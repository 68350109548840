<template>
  <div class="outer-box vertical-center-transform green-border-box">
    <div class="col-xs-12 text-center" style="margin-top: 20px;">
      <img class="pickl-logo" src="/img/pickl-logo.png" />
    </div>
    <div v-if="accountRemoved">
      <h3 data-v-6cc038e8="" style="font-size:175%">Thanks again for trying <span data-v-6cc038e8="" class="green-text">PICKL!</span></h3><br/><br/>
      <h4>Your account has beend removed from PICKL!</h4>
    </div>
    <div v-else>
      <h3 data-v-6cc038e8="" style="font-size:175%">Thank you for trying <span data-v-6cc038e8="" class="green-text">PICKL!</span></h3><br/><br/>
      <label style="color:black;font-weight: 500;">Before you go:<br/> Are you sure there is no money in your PICKL account for cashout? <br/>
        There might also be some $5 activities near you now. </label>
      <br/><br/><br/>
      <div class="col-sm-6 col-sm-offset-3">
          <form v-on:submit.prevent="sendRemoveAccountOtp" data-vv-scope="remove-account-form" v-if="step == 1">
              <label style="text-align: left;color: black;">We're always looking to improve, please share your reason for leaving us:</label><br/>
              <select class="input" v-model="reason" name="reason"  v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('remove-account-form.reason') }">
                  <option value="">Select Reason of deleting your account</option>
                  <option value="Not enough PICKL activities near me">Not enough PICKL activities near me</option>
                  <option value="I tried PICKL and it's not for me">I tried PICKL and it's not for me</option>
                  <option value="I prefer to not say">I prefer to not say</option>
                  <option value="Other">Other</option>
              </select>
              <input name="reason-input" class="phone-field" v-validate="'required'" v-model="otherReason" v-if="reason =='Other'" style="height: 45px; padding-left:10px;width: 100%;" placeholder="Enter reason of deleting account"  :class="{'input': true, 'is-danger': errors.has('remove-account-form.reason-input') }"/>
              <label style="text-align: left;color: black;">Enter your mobile number:</label>
              <select class="country-code-field" name="country-code" v-model="isd_code" v-validate="'required'">
                <option value="+1">United States(+1)</option>
                <option value="+1">Canada(+1)</option>
              </select>
            <the-mask v-validate="'required'" name="mobile-number" class="phone-field" mask="### ###-####" type="tel" autocomplete="mobile" placeholder="Mobile Number" v-model="mobile_number" :class="{'input': true, 'is-danger': errors.has('remove-account-form.mobile-number') }"></the-mask>
              <div class="col-sm-12" style="padding-right: 0;">
                <button class="btn-negative pull-right" type="submit" style="margin-top: 0px !important; font-size: 15px;font-weight: 100;float: left; background: #cb4f40; color: #fff; padding: 10px 40px;" :disabled="loading">SEND OTP</button><br/><br/>
              </div>
          </form>
          <form v-on:submit.prevent="verifyAndRemoveAccount" data-vv-scope="verify-otp-form" v-if="step == 2">
            <div class="two-step">
                <h2>Two-factor authentication</h2>
                Verification code has been sent to <u><strong>mobile</strong></u>. Enter the code.
                <label class="error-msg" v-html="errorMessage"></label>
                    <div>
                        <input type="tel" maxlength="1" v-for="n in 6" :key="n" ref="two-step" v-on:keydown="handleKeyPress(n - 1, $event)" :autofocus="1 == n" />
                    </div>
                    <!-- <div class="resend-text">Didn't get a verification code?
                      <a href="javascript:;" @click="sendVerificationCodeAgain" class="purple-link">Send Again</a></div> -->
                      <div class="col-sm-12" style="padding-right: 0;">
                <button class="btn-negative pull-right" type="submit" style="margin-top: 0px !important; font-size: 15px;font-weight: 100;float: left; background: #cb4f40; color: #fff; padding: 10px 40px;" :disabled="loading">VERIFY & REMOVE ACCOUNT</button><br/><br/>
              </div>
            </div>
          </form>
      </div>
    </div>
  </div>
</template>
<script>
import { TheMask } from 'vue-the-mask'

export default {
  name: 'RemoveAccountPage',
  components: {
    TheMask
  },
  data: function () {
    return {
      busy: false,
      errorMessage: '',
      step: 1,
      iso_code: 'US',
      isd_code: '+1',
      countries: [],
      mobile_number: '',
      reason: '',
      otherReason: '',
      accountRemoved: false,
      loading: false,
      deviceCheckCompleted: false
    }
  },
  methods: {
    verifyAndRemoveAccount () {
      let twoStep = this.$refs['two-step']
      let code = ''
      for (var n = 0; n < 6; ++n) {
        code += twoStep[n].value
      }
      console.log(code.length)
      if (code.length === 6) {
        this.$store.dispatch('verifyOtpAndRemoveAccount', { mobile_number: this.isd_code + this.mobile_number, otp: code }).then((response) => {
          this.accountRemoved = true
        }, (error) => {
          this.errorMessage = error.body.message
          this.$notify({ type: 'error', text: error.body.message })
        })
      }
    },

    sendRemoveAccountOtp () {
      this.$validator.validateAll('remove-account-form').then((result) => {
        if (result) {
          if (this.reason === 'Other') {
            this.reason = this.otherReason
          }
          this.$store.dispatch('sendOtpToVerifyAccountRemoval', { mobile_number: this.isd_code + this.mobile_number, reason: this.reason }).then((response) => {
            this.step = 2
          }, (error) => {
            console.log(error.body.message)
            this.$notify({ type: 'error', text: error.body.message })
            this.loading = false
          })
        } else {
          console.log(this.errors)
        }
      })
    },
    handleKeyPress (n, e) {
      let twoStep = this.$refs['two-step']
      let key = e.keyCode
      if (key >= 96 && key <= 105) key = key - 48

      if (key >= 48 && key <= 57) {
        twoStep[n].value = String.fromCharCode(key)
        if (n !== 5) {
          twoStep[n + 1].focus()
        }
      } else if (key === 8) {
        if (twoStep[n].value.length > 0) {
          twoStep[n].value = ''
        } else if (n !== 0) {
          twoStep[n - 1].value = ''
        }

        if (n !== 0) twoStep[n - 1].focus()
      }
      if (key !== 9 && key !== 13) {
        e.preventDefault()
      }
    }
  }
}
</script>
<style scoped>
.two-step {
    font-size: 17px;
    color: rgba(0,0,0,0.7);
}

.two-step input[type=tel] {
    width: 14%;
    margin-right: 3.2%;
    height: 54px;
    font-size: 24px;
    padding: 15px 0;
    text-align: center;
    margin-top: 40px;
    font-weight: 700;
}

.two-step input[type=tel]:last-child {
    margin-right: 0;
}

.two-step .resend-text {
    font-size: 18px;
    margin: 4px 0;
}

@media only screen and (max-width: 575px) {
    h2 {
        font-size: 35px;
        line-height: 45px;
    }

    .two-step input[type=tel] {
        height: 40px;
        padding: 8px 0;
    }
}
.vertical-center-transform {
  width: 100%;
  text-align: center;
}

.green-border-box {
  margin-bottom: 15px;
}

.border-circle {
  width: 19%;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  display: inline-block;
  margin-bottom: 44px;
}

.border-circle:before {
  content: " ";
  padding-top: 100%;
  display: block;
}

hr.journey-string {
  width: 5%;
  margin: 10% 4px 0;
  vertical-align: top;
  border-width: 2px;
  border-color: #d9d9d9;
  display: inline-block;
}

.modal-content .green-border-box .plain-center-header {
  font-weight: 500;
  font-size: 22px;
  color: #333333;
  margin-top: 0;
  margin-bottom: 15px;
  text-align: center;
}

.circle-label {
  font-size: 16px;
  color: black;
  width: 100%;
  text-align: center;
  display: block;
  position: absolute;
  bottom: -50px;
  left: 0;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 22px;
  height: 44px;
  overflow: hidden;
}

.circle-label:last-child {
  padding-right: 0;
}

.completion-time {
  border-radius: 360px;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 13px;
  padding: 5px 15px;
  background: #f02a38;
  background: -webkit-linear-gradient(left, #f02a38 0%, #ff434c 100%);
  background: -moz-linear-gradient(left, #f02a38 0%, #ff434c 100%);
  background: linear-gradient(to right, #f02a38 0%, #ff434c 100%);
}

.rating img:last-child {
  margin-right: 0;
}

.rating strong {
  font-weight: 500;
  font-size: 18px;
  color: black;
  margin-right: 5px;
}

.top-part {
  padding: 15px;
}

.middle-part {
  padding: 0 15px 15px;
}

.top-part h4,
.top-part h5 {
  padding: 0 75px;
  margin: 0;
}

.top-part h4 {
  font-weight: 500;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 30px;
}

.top-part h5 {
  font-size: 17px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
  margin-bottom: 8px;
}

.product-logo {
  left: auto;
  right: 0;
}

.middle-part .address {
  font-size: 17px;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}

/* .middle-part .address:first-of-type {
    min-height: 48px;
  } */

.middle-part .status {
  font-size: 19px;
  font-weight: 500;
}

.bottom-part {
  padding: 10px 0;
  background: #f7f8f9;
  font-weight: 500;
  font-size: 19px;
  color: rgba(0, 0, 0, 0.8);
  vertical-align: text-top;
  line-height: 35px;
}

.bottom-part img {
  vertical-align: text-top;
  margin-right: 3px;
}

.bottom-part .pickl-date {
  border-right: 1px solid #cfd1d3;
}

.rating {
  background-color: #f7f8f9;
  /* padding: 30px 20px; */
  float: left;
  width: 100%;
  min-height: 233px;
}

.issues {
  min-height: 233px;
}

.rating img {
  width: 19px;
  height: 17px;
  margin-right: 3px;
}

.rating img:last-child {
  margin-right: 0;
}

.attr-imgs {
  padding: 20px 10px 0;
}

.photo {
  padding: 0 10px;
  margin-bottom: 40px;
}

.img-contain {
  position: relative;
  margin-bottom: 10px;
  display: block;
  width: 100%;
  overflow: hidden;
}

.img-contain:before {
  padding-top: 100%;
  display: block;
  content: " ";
}

.img-contain img {
  width: 100%;
  height: auto;
  cursor: pointer;
  /* max-width: 100%;
    max-height: 100%; */
}

.img-contain .img-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  /* background-color: rgba(0, 0, 0, 0.3); */
  font-weight: 500;
  font-size: 22px;
  text-transform: capitalize;
  pointer-events: none;
}

.img-contain .img-overlay .img-count {
  border-radius: 50%;
  background-color: #00e05e;
  border: 2px solid white;
  font-size: 17px;
  width: 25px;
  height: 25px;
  display: block;
  text-align: center;
  position: absolute;
  top: 5px;
  left: 5px;
  line-height: 21px;
}

.attr-time {
  font-size: 17px;
  color: #333333;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.normal-text {
  color: rgba(0, 0, 0, 0.9);
  font-size: 18px;
}

label {
  width: 100%;
  color: rgba(89, 89, 89, 0.9);
  font-size: 17px;
  margin-top: 0;
  margin-bottom: 0;
}

.rating label {
  margin-top: 30px;
}

.btn-lg-grey,
.btn-lg-green {
  width: 200px;
}

textarea {
  margin-bottom: 0;
}

.rating h5 {
  font-weight: 500;
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 14px;
}

.rate-picklr {
  cursor: pointer;
  margin-bottom: 45px;
}

.rate-picklr span {
  display: inline-block;
}

.rate-picklr img {
  width: 33px;
  height: 31px;
  margin: 0 8px;
}

.showOnMobile {
  display: none;
}

.address img {
  vertical-align: text-top;
}

.img-contain .img-overlay span:last-child {
  padding: 3px;
  background: rgba(0, 0, 0, 0.8);
}

.overlay {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.overlay .vertical-center-transform {
  color: white;
}

.first-block {
  min-height: 230px;
}

.product-details .photo {
  margin-bottom: 14px;
}

.product-details .attr-imgs {
  padding-top: 0;
}

.product-details .missing-image {
  border: 1px dashed black;
}

@media only screen and (min-width: 1096px) and (max-width: 1199px) {
  .bottom-part {
    font-size: 15px;
    line-height: 30px;
  }

  .bottom-part > div {
    padding-left: 0;
    padding-right: 0;
  }

  .bottom-part img {
    height: 17px;
    width: auto;
  }
}
.modal-dialog.modal-lg {
  max-width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .border-circle {
    font-size: 13px;
    line-height: 17px;
  }

  .border-circle .circle-label {
    font-size: 13px;
  }

  .img-contain .img-overlay {
    font-size: 17px;
  }
}

@media only screen and (max-width: 767px) {
  .bottom-part {
    padding: 0 15px;
  }

  .bottom-part .pickl-date,
  .bottom-part .pickl-time {
    padding: 10px 0;
  }

  .bottom-part .pickl-date {
    border-bottom: 1px solid #cfd1d3;
    border-right: none;
  }

  .rating {
    min-height: auto;
  }

  .issues {
    min-height: auto;
  }
}

@media only screen and (max-width: 575px) {
  .company-logo {
    left: 20%;
  }

  .product-logo {
    right: 20%;
  }

  .top-part h4 {
    padding: 0;
    padding-top: 70px;
  }
  .top-part h5 {
    padding: 0;
  }

  .showOnMobile {
    display: inline;
  }

  /* .rating {
      padding: 15px;
    } */

  .rating strong {
    font-size: 16px;
  }

  .rated img {
    width: 15px;
    height: auto;
  }

  .img-contain .img-overlay {
    font-size: 17px;
  }

  .border-circle {
    font-size: 14px;
    line-height: 17px;
    min-width: 75px;
    min-height: 75px;
    display: block;
    margin-bottom: 0;
  }

  .border-circle .circle-label {
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 20px);
    width: auto;
    text-align: left;
    bottom: auto;
    height: auto;
  }

  hr.journey-string {
    width: 100%;
    border-width: 1px;
    margin: 25px 0;
  }

  .btn-lg-grey,
  .btn-lg-green {
    width: 120px;
  }
}

.review-attention strong {
  color: #cb4f40;
}
.team-comment {
  float: left;
  text-align: left;
  border: 1px solid #12db6d;
  margin: 10px;
  border-radius: 10px;
  padding: 5px;
  background: ghostwhite;
}
.your-comment {
  float: right;
  text-align: right;
  border: 1px solid #12db6d;
  margin: 10px;
  padding: 5px;
  border-radius: 10px;
  background: #e8fff2;
}
.outer-box {
  margin: 0 auto;
  max-width: 1366px;
  width: 100%;
  background-image: url("/img/background-portrait.png");
  background-size: 50% 100%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.pickl-logo {
  margin-bottom: 30px;
  width: 120px;
  height: 120px;
  border-radius: 7px;
}

.login-box {
  margin: 5% auto 0;
  padding: 10px 90px 20px;
}

.video-out {
  padding-left: 0;
  left: auto;
  right: 0;
}

.video-box {
  margin: 0 auto;
  /* margin: 0 0 20px; */
  padding: 25px 0 15px;
}

.video-box h3 {
  font-weight: 700;
  text-align: center;
  font-size: 44px;
  color: black;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .login-box {
    padding: 10px 35px 20px;
  }
}

@media only screen and (max-width: 991px) {
  .outer-box {
    background-size: 100% 100vh;
    background-repeat: repeat-y;
    position: static;
    transform: none;
  }

  .video-out {
    padding-left: 15px;
    min-height: auto;
    position: static;
    transform: none;
  }

  .video-box {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .login-box {
    padding: 15px;
  }

  .video-box h3 {
    font-size: 36px;
    padding: 15px;
  }
  .country-code-field, .phone-field {
    width: 100% !important;
  }
}

@media only screen and (max-height: 660px) {
  .outer-box {
    position: static;
    transform: none;
  }

}
select,
select:hover {
  border: 1px solid #6f3053;
  border-radius: 3px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
  color: black;
  width: 100%;
  padding: 14px 42px 14px 16px;
  height: 50px;
  background-position: right 11px center;
  background-size: 20px 12px;
  background-image: url("/img/Create Pickl/selectproductDropdown_icon.png");
  background-color: white;
}
.country-code-field,.country-code-field:hover {
  width:35%;
  float: left;
}
.phone-field {
  width:63%;
  float: right;
}
</style>
