var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "outer-box vertical-center-transform green-border-box" },
    [
      _vm._m(0),
      _vm.accountRemoved
        ? _c("div", [
            _vm._m(1),
            _c("br"),
            _c("br"),
            _c("h4", [_vm._v("Your account has beend removed from PICKL!")])
          ])
        : _c("div", [
            _vm._m(2),
            _c("br"),
            _c("br"),
            _vm._m(3),
            _c("br"),
            _c("br"),
            _c("br"),
            _c("div", { staticClass: "col-sm-6 col-sm-offset-3" }, [
              _vm.step == 1
                ? _c(
                    "form",
                    {
                      attrs: { "data-vv-scope": "remove-account-form" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.sendRemoveAccountOtp($event)
                        }
                      }
                    },
                    [
                      _c(
                        "label",
                        {
                          staticStyle: { "text-align": "left", color: "black" }
                        },
                        [
                          _vm._v(
                            "We're always looking to improve, please share your reason for leaving us:"
                          )
                        ]
                      ),
                      _c("br"),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.reason,
                              expression: "reason"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          staticClass: "input",
                          class: {
                            input: true,
                            "is-danger": _vm.errors.has(
                              "remove-account-form.reason"
                            )
                          },
                          attrs: { name: "reason" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.reason = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("Select Reason of deleting your account")
                          ]),
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "Not enough PICKL activities near me"
                              }
                            },
                            [_vm._v("Not enough PICKL activities near me")]
                          ),
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "I tried PICKL and it's not for me"
                              }
                            },
                            [_vm._v("I tried PICKL and it's not for me")]
                          ),
                          _c(
                            "option",
                            { attrs: { value: "I prefer to not say" } },
                            [_vm._v("I prefer to not say")]
                          ),
                          _c("option", { attrs: { value: "Other" } }, [
                            _vm._v("Other")
                          ])
                        ]
                      ),
                      _vm.reason == "Other"
                        ? _c("input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.otherReason,
                                expression: "otherReason"
                              }
                            ],
                            staticClass: "phone-field",
                            class: {
                              input: true,
                              "is-danger": _vm.errors.has(
                                "remove-account-form.reason-input"
                              )
                            },
                            staticStyle: {
                              height: "45px",
                              "padding-left": "10px",
                              width: "100%"
                            },
                            attrs: {
                              name: "reason-input",
                              placeholder: "Enter reason of deleting account"
                            },
                            domProps: { value: _vm.otherReason },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.otherReason = $event.target.value
                              }
                            }
                          })
                        : _vm._e(),
                      _c(
                        "label",
                        {
                          staticStyle: { "text-align": "left", color: "black" }
                        },
                        [_vm._v("Enter your mobile number:")]
                      ),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.isd_code,
                              expression: "isd_code"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          staticClass: "country-code-field",
                          attrs: { name: "country-code" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.isd_code = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "+1" } }, [
                            _vm._v("United States(+1)")
                          ]),
                          _c("option", { attrs: { value: "+1" } }, [
                            _vm._v("Canada(+1)")
                          ])
                        ]
                      ),
                      _c("the-mask", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        staticClass: "phone-field",
                        class: {
                          input: true,
                          "is-danger": _vm.errors.has(
                            "remove-account-form.mobile-number"
                          )
                        },
                        attrs: {
                          name: "mobile-number",
                          mask: "### ###-####",
                          type: "tel",
                          autocomplete: "mobile",
                          placeholder: "Mobile Number"
                        },
                        model: {
                          value: _vm.mobile_number,
                          callback: function($$v) {
                            _vm.mobile_number = $$v
                          },
                          expression: "mobile_number"
                        }
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "col-sm-12",
                          staticStyle: { "padding-right": "0" }
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn-negative pull-right",
                              staticStyle: {
                                "margin-top": "0px !important",
                                "font-size": "15px",
                                "font-weight": "100",
                                float: "left",
                                background: "#cb4f40",
                                color: "#fff",
                                padding: "10px 40px"
                              },
                              attrs: { type: "submit", disabled: _vm.loading }
                            },
                            [_vm._v("SEND OTP")]
                          ),
                          _c("br"),
                          _c("br")
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.step == 2
                ? _c(
                    "form",
                    {
                      attrs: { "data-vv-scope": "verify-otp-form" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.verifyAndRemoveAccount($event)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "two-step" }, [
                        _c("h2", [_vm._v("Two-factor authentication")]),
                        _vm._v(
                          "\n              Verification code has been sent to "
                        ),
                        _vm._m(4),
                        _vm._v(". Enter the code.\n              "),
                        _c("label", {
                          staticClass: "error-msg",
                          domProps: { innerHTML: _vm._s(_vm.errorMessage) }
                        }),
                        _c(
                          "div",
                          _vm._l(6, function(n) {
                            return _c("input", {
                              key: n,
                              ref: "two-step",
                              refInFor: true,
                              attrs: {
                                type: "tel",
                                maxlength: "1",
                                autofocus: 1 == n
                              },
                              on: {
                                keydown: function($event) {
                                  return _vm.handleKeyPress(n - 1, $event)
                                }
                              }
                            })
                          }),
                          0
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "col-sm-12",
                            staticStyle: { "padding-right": "0" }
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn-negative pull-right",
                                staticStyle: {
                                  "margin-top": "0px !important",
                                  "font-size": "15px",
                                  "font-weight": "100",
                                  float: "left",
                                  background: "#cb4f40",
                                  color: "#fff",
                                  padding: "10px 40px"
                                },
                                attrs: { type: "submit", disabled: _vm.loading }
                              },
                              [_vm._v("VERIFY & REMOVE ACCOUNT")]
                            ),
                            _c("br"),
                            _c("br")
                          ]
                        )
                      ])
                    ]
                  )
                : _vm._e()
            ])
          ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "col-xs-12 text-center",
        staticStyle: { "margin-top": "20px" }
      },
      [
        _c("img", {
          staticClass: "pickl-logo",
          attrs: { src: "/img/pickl-logo.png" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h3",
      {
        staticStyle: { "font-size": "175%" },
        attrs: { "data-v-6cc038e8": "" }
      },
      [
        _vm._v("Thanks again for trying "),
        _c(
          "span",
          { staticClass: "green-text", attrs: { "data-v-6cc038e8": "" } },
          [_vm._v("PICKL!")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h3",
      {
        staticStyle: { "font-size": "175%" },
        attrs: { "data-v-6cc038e8": "" }
      },
      [
        _vm._v("Thank you for trying "),
        _c(
          "span",
          { staticClass: "green-text", attrs: { "data-v-6cc038e8": "" } },
          [_vm._v("PICKL!")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticStyle: { color: "black", "font-weight": "500" } },
      [
        _vm._v("Before you go:"),
        _c("br"),
        _vm._v(
          " Are you sure there is no money in your PICKL account for cashout? "
        ),
        _c("br"),
        _vm._v("\n      There might also be some $5 activities near you now. ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("u", [_c("strong", [_vm._v("mobile")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }